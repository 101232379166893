* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  font-family: 'Playfair Display', serif;
}

body {
  height: 100%;
}

nav {
  background-color: black;
  position: fixed;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  z-index: 1;
}

#nav-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

nav a {
  color: white;
  text-decoration: none;
  padding: 10px;
}

nav a:hover {
  background-color: white;
  color: black;
  opacity: 50%;
}

#hamburger, #close {
  position: absolute;
  color: white;
  right: 20px;
  top: 20px;
  visibility: hidden;
  cursor: pointer;
}

#banner {
  position: relative;
  top: 100px;
  background-color: black;
  width: 100%;
  height: 500px;
  color: white;
  padding: 20px;
  font-size: 2.5rem;
  font-family: 'Playfair Display', serif;
  margin: 0 auto;
}

#banner h1 {
  font-size: 4rem;
  display: flex;
  justify-content: center;
}

#img-container {
  position: relative;
  width: 100%;
  height: 700px;
}

#profile-pic {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.img-button {
  position: absolute;
  background-color: white;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  cursor: pointer;
}

#tom0 {
  background-color: black;
}

.img-button:hover {
  background-color: black;
}

#tom0 {
  left: 46%;
}

#tom1 {
  left: 48%;
}

#tom2 {
  left: 50%;
}

#bio {
  background-color: rgb(153, 117, 63);
  color: white;
  padding: 10%;
}

#bio h2 {
  font-size: 2rem;
}

p {
  letter-spacing: .1rem;
  line-height: 1.5rem;
  word-spacing: .3rem;
}

audio {
  margin: 5% 0;
  width: 100%;
}

audio::-webkit-media-controls-enclosure {
  background-color:rgb(153, 117, 63);
  width: 50%;
}

#audio {
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 5%;
}

.audio-container {
  padding-top: 2%;
}

#quote {
  display: flex;
  background-color: black;
  color: white;
  font-size: 1.8rem;
}

#quote-text {
  width: 50%;
  display: flex;
  flex-direction: column;
}

#quote-text p {
  justify-content: center;
  padding: 50px;
}

#quote-image img {
  width: 100%;
  object-fit:scale-down;
}

#profile {
  position: relative;
  top: 100px;
}

.error {
  color: red;
}

#login-box {
  position: relative;
  top: 200px;
  display: flex;
  flex-direction: column;
  width: 40%;
  margin: 0 auto;
  border: 1px solid black;
  border-radius: 5%;
  padding: 10px;
  box-shadow: 2px 2px 5px;
}

#login-box > * {
  margin: 2%;
  padding: 2%;
  z-index: 10;
}

table {
  width: 50%;
}

td {
  padding: 2%;
}

#performances {
  background-color:rgb(153, 117, 63);
  color: white;
  padding: 5%;
}

.table-header {
  font-weight: bolder;
}

.table-data {
  font-weight: lighter;
}

button {
  width: 100px;
  height: 40px;
  border-radius: 20%;
  background-color: rgb(153, 117, 63);
  color: white;
  box-shadow: 1px 1px 5px;
  margin: 5px;
}

button:active {
  box-shadow: inset 1px 1px 5px;
}

.black {
  background-color: rgb(100, 100, 100);
}

#contact {
  display: flex;
  background-color: rgb(153, 117, 63);
}

.contact-box {
  width: 50%;
  padding: 5%;
}

#sketch {
  width: 50%;
  height: 600px;
  object-fit: cover;
}

h1, h2, h3, a, li {
  color: white;
}

.social-icon {
  color: rgb(220, 220, 220);
  font-size: 2.5rem;
  text-decoration: none;
}

.social-icon:hover {
  font-size: 3rem;
  transition: .5s;
}

.social-text {
  padding-left: 5%;
  text-decoration: none;
}

.fa-home, .fa-briefcase {
  padding: 5% 0;
}

#resource-container {
  position: relative;
  top: 100px;
  height: 100%;
  background-color:rgb(153, 117, 63);
  padding: 10%;
}

li {
  list-style-type: disc; 
}

#links {
  padding: 5%;
}

.link {
  padding: 2%;
}

.link:hover {
  color: purple;
}

.title {
  padding: 2%;
}

@media screen and (max-width: 850px) {
  #banner {
    font-size: 1.5rem;
    top: 50px;
  }
  
  #hamburger {
    visibility: visible;
    z-index: 10;
  }

  #close {
    z-index: 10;
  }

  nav {
    height: 50px;
    max-width: 100%;
  }

  #nav-box {
    flex-direction: column;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgb(153, 117, 63);
    visibility: hidden;
  }

  .nav-items {
    padding: 20px;
  }

  #tom0 {
    left: 44%;
  }
  
  #tom1 {
    left: 48%;
  }
  
  #tom2 {
    left: 52%;
  }

  audio::-webkit-media-controls-enclosure {
    width: 100%;
  }

  td {
    font-size: .7rem;
  }

  #quote {
    flex-direction: column;
  }

  #quote-text {
    width: 100%;
  }

  #contact {
    flex-direction: column-reverse;
  }

  #sketch {
    width: 100%;
  }

  #resource-container {
    top: 50px;
  }
}